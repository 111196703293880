<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-select
          placeholder="请选择站长代理"
          v-model="query.agentId"
          clearable
        >
          <el-option
            v-for="(item, index) in zzAgentList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col span="4">
        <eb-filter-user-list v-model="query.userId">
        </eb-filter-user-list>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="状态" v-model="query.status" clearable>
          <el-option
            v-for="(item, index) in this.const.ZZ_AGENT_ORDER_STATUS"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button type="primary" @click="doExport">导出数据</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      @selection-change="handleSelectionChange"
      row-key="id"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站长名称" prop="agentName"></el-table-column>
      <el-table-column label="用户站点" prop="agentUserId"></el-table-column>
      <el-table-column label="用户名" prop="userName"></el-table-column>

      <el-table-column label="金额" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="代付天数" prop="days"></el-table-column>
      <el-table-column label="代付时间" prop="createTime"></el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.ZZ_AGENT_ORDER_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="结算天数" prop="settleDay"></el-table-column>
      <el-table-column label="结算日期" prop="settleTime"></el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
    
    <script>
import {
  fetchAgentOrder,
  aentOrderSettle,
  ZZAgentOrderExport,
} from "@/api/zzAgent";
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    zzAgentList(state) {
      return state.zzAgent.list || [];
    },
  }),
  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      query: {
        startTime: startTime,
        endTime: endTime,
      },
      ids: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    handleSelectionChange(value) {
      console.log(this.ids);
      this.ids = [];
      value.map((item) => {
        console.log(this.ids);
        this.ids.push(item.id);
      });
    },

    // 手动结算
    doSettle(data) {
      aentOrderSettle(data).then((res) => {
        this.getList();
        this.$message.success("结算成功");
      });
    },

    doExport() {
      ZZAgentOrderExport({
        ...this.query,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `代理结算.xlsx`
        );
      });
    },

    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      this.ids = [];
      fetchAgentOrder({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
    this.$store.dispatch("fetchZZAgentListAll");
  },
};
</script>
    